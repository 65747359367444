<template>
    <div
        class="h-100 overflow-hidden"
        @click="$emit('focus')"
    >
        <div class="h-100 d-flex flex-column">
            <input
                v-model="dataset.name"
                class="w-50 mx-auto my-2"
                @input="$emit('change')"
            >
            <div class="h-100 overflow-x-hidden overflow-y-auto scrollbar-thin">
                <div
                    v-for="item, i in dataset.items"
                    :key="item.index"
                >
                    <div
                        class="
                            w-100 mb-1 px-1 bg-white d-flex align-items-center
                        "
                    >
                        <b>{{ i + 1 }})</b>
                        <input
                            ref="dataInput"
                            v-model="item.value"
                            type="number"
                            :autofocus="autofocus(item)"
                            class="input w-100 ml-1 border-0"
                            @input="$emit('change')"
                            @keyup.enter="onSubmitRow(item, i)"
                        >
                        <i
                            v-show="dataset.items.length > 1"
                            class="bi bi-dash-circle-fill ml-1 line-height-0"
                            @click="removeItem(i)"
                        />
                    </div>
                </div>
                <b-button
                    class="mx-2 mt-1 mb-2 btn-success"
                    @click="addRowBtn"
                >
                    Add Row
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'DataSet',
    props: {
        init: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            dataset: this.init,
        }
    },
    computed: {
        lastItemIndex () {
            return _(this.dataset.items).last()?.index ?? -1
        },
    },
    methods: {
        addItem () {
            this.dataset.items.push({
                index: this.lastItemIndex + 1,
                value: '',
            })
            this.$emit('change')
        },
        removeItem (index) {
            this.dataset.items.splice(index, 1)
            this.$emit('change')
        },
        reset (index = -1) {
            this.dataset.items = []
            this.addItem()

            // Reset name if dataset index is provided
            if (index > -1) {
                this.dataset.name = 'Dataset ' + (index + 1)
            }
        },
        focusRow (row) {
            this.$refs.dataInput[row].focus()
        },
        isLastItem (item) {
            return item.index === this.lastItemIndex
        },
        autofocus (item) {
            return this.dataset.index === 0 && this.isLastItem(item)
        },
        async onSubmitRow (item, row) {
            if (this.isLastItem(item)) {
                this.addItem()
                await this.$nextTick
            }
            this.focusRow(row + 1)
        },
        async addRowBtn () {
            this.addItem()
            await this.$nextTick
            this.focusRow(this.$refs.dataInput.length - 1)
        },
    },
}
</script>

<style scoped>
.bi-dash-circle-fill::before {
    margin: 0;
    padding: 0;
    font-size: large;
}

.input {
    margin-top: 2px;
    margin-bottom: 2px;
}
</style>
