<template>
    <div
        ref="image"
        class="w-0 text-left off-screen"
    >
        <div
            v-for="key in dataKeys"
            :key="key"
            class="image-item"
        >
            {{ key }}
        </div>
    </div>
</template>

<script>
import html2canvas from 'html2canvas'
import { jsPDF as JsPDF } from 'jspdf'

export default {
    name: 'PdfGenerator',
    data () {
        return {
            dataKeys: [],
        }
    },
    computed: {
        dataKeyStrings () {
            return {
                sampleSize: 'Sample Size',
                mean: 'Mean (𝒙̄)',
                stdDev: 'Std Dev (𝒔)',
                min: 'Min',
                twentyFifthPercentile: '25ᵗʰ Percentile',
                median: 'Median',
                seventyFifthPercentile: '75ᵗʰ Percentile',
                max: 'Max',
                variance: 'Variance (𝒔²)',
                sum: 'Sum (𝞢𝒙)',
                mode: 'Mode',
                range: 'Range',
            }
        },
    },
    methods: {
        async generate (data) {
            // Begin generating PDF document
            const doc = new JsPDF({ unit: 'pt', lineHeight: 1.5 })
            const lineHeight = doc.getLineHeight() * 72 / 96
            const pageWidth = doc.internal.pageSize.getWidth()

            // Add title
            doc.setFont('helvetica', 'bold')
            doc.setFontSize(16)
            doc.text(data.datasetName, pageWidth / 2, 30, 'center')

            // Add data values
            doc.setFont('helvetica', 'normal')
            doc.setFontSize(12)
            const dataStrings = doc.splitTextToSize(
                data.dataVals.join(', '),
                pageWidth - 80,
            )
            let x = 47
            let align = 'left'
            if (dataStrings.length === 1) {
                dataStrings[0] = 'Data: ' + dataStrings[0]
                x = pageWidth / 2
                align = 'center'
            } else {
                doc.text('Data:', 15, 60)
            }
            doc.text(dataStrings, x, 60, align)

            // Add data summary
            const xLeft = pageWidth * 2 / 7
            const xRight = pageWidth * 4 / 7
            let y = 60 + (dataStrings.length + 1) * lineHeight
            doc.text('Data Summary:', xLeft, y)
            Object.entries(data.tableData).forEach(element => {
                // Add key to HTML element under last key for image generation
                this.dataKeys.push(this.dataKeyStrings[element[0]])

                // Add value to PDF document under last value
                doc.text(element[1].toString(), xRight, y += lineHeight)
            })

            // Generate image of data summary keys and add to PDF document
            await this.$nextTick()
            html2canvas(
                this.$refs.image,
                { backgroundColor: null, scale: 3 }
            ).then(function (canvas) {
                doc.addImage(
                    canvas.toDataURL('image/png'),
                    'PNG',
                    xLeft,
                    47 + (dataStrings.length + 2) * lineHeight,
                    77.639,
                    215,
                )
                doc.save(data.datasetName + '.pdf')
            })

            // Reset data summary keys
            this.dataKeys = []
        },
    },
}
</script>

<style scoped>
.image-item {
    line-height: 1.5;
    color: black;
    font-family: Arial, Helvetica, sans-serif;
}
</style>
