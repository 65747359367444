<template lang="html">
    <div class="container main h-screen px-0 d-flex flex-column">
        <top-navigation-bar
            title="Summarize Data"
            right-nav="trash"
            class="mx-0"
            @click="resetAllDatasets"
            @leave="removeEvents"
        />
        <div
            class="mx-auto mt-1 px-1 py-1 border rounded-sm"
        >
            {{ focusedDataset + 1 }} / {{ numberOfDatasets }}
        </div>
        <div
            ref="datasetsContainer"
            class="
                w-100 h-100 pt-1 pb-3 text-nowrap overflow-x-auto
                overflow-y-hidden
            "
            @mousedown="onMouseDown"
            @scroll="onScroll"
            @mouseup="onMouseUp"
        >
            <div class="w-25 d-inline-block" />
            <DataSet
                v-for="dataset, i in datasets"
                :key="dataset.index"
                ref="datasets"
                :init="dataset"
                class="
                    w-50 h-100 mx-2 d-inline-block overflow-auto border
                    rounded-lg shadow
                "
                @focus="focusDataset(i)"
                @change="saveDatasets"
            />
            <div class="w-25 d-inline-block" />
        </div>
        <div class="sticky-bottom m-3 d-flex">
            <button
                class="action-btn w-75 mr-1"
                block
                @click="calculatePressed()"
            >
                Calculate
            </button>
            <button
                class="action-btn w-25 ml-1"
                block
                @click="resetFocusedDataset"
            >
                Reset
            </button>
        </div>

        <b-modal
            ref="refModal"
            size="lg"
            hide-footer
        >
            <template #modal-title>
                Data Summary
                <i
                    v-b-hover="pdfHover"
                    v-b-tooltip.hover
                    title="Download PDF"
                    class="
                        pdf-download-btn bi bi-file-earmark-arrow-down-fill
                        ml-2 line-height-0 float-right
                    "
                    :class="[pdfHovering ? 'text-dark cursor-pointer' : '']"
                    @click="generatePdf"
                />
            </template>
            <div class="d-block text-center">
                <b-table
                    :items="[calculatedData.tableData]"
                    :fields="tableFields"
                    outlined
                    stacked
                    caption-top
                    class="tableRow"
                />
                <div>
                    <b-tabs content-class="mt-3">
                        <b-tab title="Dot Plot">
                            <highcharts
                                class="py-1"
                                :options="dotPlotChart.chartOptions"
                            />
                        </b-tab>
                        <b-tab
                            title="Histogram Plot"
                            active
                        >
                            <highcharts
                                class="py-1"
                                :options="histogramChart.chartOptions"
                            />
                        </b-tab>
                        <b-tab title="Box Plot">
                            <highcharts
                                class="py-1"
                                :options="boxPlotChart.chartOptions"
                            />
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </b-modal>
        <PdfGenerator ref="pdfGenerator" />
    </div>
</template>

<script>
import { numberOfDatasets } from '@/../datasets.config'
import TopNavigationBar from '@/components/top-navigation-bar.vue'
import sampleData from '@/js/lib/sample-data.js'
import sData from '@/js/summarizeData/summarize-data.js'
import localStore from '@/store/localStore.js'
import { Chart } from 'highcharts-vue'
import _ from 'lodash'
import DataSet from './summarize-data/dataset.vue'
import PdfGenerator from './summarize-data/pdf-generator.vue'

export default {
    name: 'SummarizeData',
    components: {
        TopNavigationBar,
        DataSet,
        highcharts: Chart,
        PdfGenerator,
    },
    data () {
        return {
            datasets: [],
            focusedDataset: 0,
            numberOfDatasets: numberOfDatasets,
            pdfHovering: false,
            calculatedData: [],
            tableFields: [
                'sampleSize',
                {
                    key: 'mean',
                    label: 'Mean (𝒙̄)'
                },
                {
                    key: 'stdDev',
                    label: 'Std Dev. (𝒔)'
                },
                'min',
                {
                    key: 'twentyFifthPercentile',
                    label: '25th Percentile',
                },
                'median',
                {
                    key: 'seventyFifthPercentile',
                    label: '75th Percentile',
                },
                'max',
                {
                    key: 'variance',
                    label: 'Variance (𝒔²)',
                },
                {
                    key: 'sum',
                    label: 'Sum (𝞢𝒙)',
                },
                'mode',
                'range'
            ],
            mouseDown: false,
            scrolling: false,
            // Testing
            isTesting: false,
            inputDataTest: sampleData.summarizeData.case2,
        }
    },
    computed: {
        dotPlotChart () {
            return {
                chartOptions: {
                    chart: {
                        type: 'bubble',
                        borderWidth: 0,
                    },
                    legend: {
                        enabled: false
                    },
                    series: [{
                        data: this.calculatedData?.dotPlot,
                        sizeByAbsoluteValue: true,
                        zThreshold: -1,
                        maxSize: 15,
                        minSize: 15,
                        color: 'red',
                    }]
                }
            }
        },
        histogramChart () {
            return {
                chartOptions: {
                    legend: {
                        enabled: false
                    },
                    chart: {
                        borderWidth: 0,
                    },
                    xAxis: [{
                        alignTicks: true,
                        lineColor: 'transparent',
                        opposite: true,
                        labels: {
                            enabled: false
                        },
                    }, {
                        title: { text: 'Data' },
                    }],
                    yAxis: [{
                        alignTicks: true,
                        lineColor: 'transparent',
                        opposite: true,
                        labels: {
                            enabled: false
                        },
                    }, {
                        title: { text: 'Frequency' },
                    }],
                    series: [{
                        name: 'Histogram',
                        type: 'histogram',
                        xAxis: 1,
                        yAxis: 1,
                        baseSeries: 's1',
                        zIndex: 1,
                        color: 'red',
                    }, {
                        type: 'scatter',
                        data: this.calculatedData?.dataVals,
                        id: 's1',
                        marker: false,
                        visable: false,
                        enableMouseTracking: false
                    }]
                }
            }
        },
        boxPlotChart () {
            return {
                chartOptions: {
                    chart: {
                        type: 'boxplot',
                        inverted: true,
                        borderWidth: 0,
                    },
                    legend: {
                        enabled: false
                    },
                    xAxis: {
                        visable: false,
                        lineWidth: 0,
                        tickLength: 0,
                        labels: {
                            enabled: false
                        },
                    },
                    yAxis: {
                        visable: false,
                        lineWidth: 0,
                        tickLength: 0,
                        labels: {
                            enabled: false
                        },
                    },
                    series: [{
                        name: 'Observations',
                        data: [this.calculatedData.boxPlot?.output],
                        color: 'black',
                        fillColor: 'red',
                        medianColor: 'black',
                        medianWidth: 3,
                    }, {
                        type: 'scatter',
                        tooltip: {
                            enabled: false
                        },
                        dataLabels: {
                            format: '{key}',
                            enabled: true,
                            allowOverlap: true,
                            overflow: true,
                            crop: false,
                            style: {
                                fontWeight: 'bold',
                                fontSize: 14,
                            },
                        },
                        data: this.calculatedData.boxPlot?.outputLabels,
                        enableMouseTracking: false,
                        marker: {
                            enabled: false,
                        }
                    }]
                }
            }
        },
    },
    beforeMount () {
        this.initializeDatasets()
    },
    mounted () {
        window.addEventListener('keydown', this.handleArrowKeys)
        setTimeout(() => this.focusDataset(0), 100)
    },
    beforeUnmount () {
        this.removeEvents()
    },
    methods: {
        initializeDatasets () {
            // Get stored datasets
            var store = localStore.getStore('summarize-data')?.data
            this.datasets = store?.length ? store : []

            // Remove extra datasets if there are too many
            this.datasets.splice(numberOfDatasets)

            // Add empty datasets if there are not enough
            for (let i = this.datasets.length; i < numberOfDatasets; i++) {
                this.datasets[i] = {
                    index: i,
                    name: 'Dataset ' + (i + 1),
                    items: [{
                        index: 0,
                        value: '',
                    }],
                }
            }
        },
        focusDataset (index) {
            index = index < 0 ? 0 : index
            const max = this.datasets.length - 1
            this.focusedDataset = index > max ? max : index
            this.$refs.datasets[this.focusedDataset].$el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            })
        },
        resetFocusedDataset () {
            const datasetComponent = this.$refs.datasets[this.focusedDataset]
            if (confirm('Reset ' + datasetComponent.dataset.name + '?')) {
                datasetComponent.reset()
            }
        },
        resetAllDatasets () {
            if (confirm('Reset all datasets?')) {
                this.$refs.datasets.forEach((datasetComponent, i) => {
                    datasetComponent.reset(i)
                })
            }
        },
        saveDatasets () {
            var data = this.datasets
            localStore.setStoreItem('summarize-data', { data })
        },
        showModal () {
            this.$refs.refModal.show()
        },
        onMouseDown () {
            this.mouseDown = true
        },
        onScroll () {
            this.scrolling = this.mouseDown
        },
        onMouseUp () {
            this.mouseDown = false
            if (this.scrolling) {
                this.scrolling = false
                this.focusDataset(
                    Math.round(
                        this.$refs.datasetsContainer.scrollLeft /
                        this.$refs.datasetsContainer.scrollWidth *
                        (this.datasets.length + 1)
                    )
                )
            }
        },
        handleArrowKeys (event) {
            if (event.key === 'ArrowLeft') {
                event.preventDefault()
                this.focusDataset(this.focusedDataset - 1)
            } else if (event.key === 'ArrowRight') {
                event.preventDefault()
                this.focusDataset(this.focusedDataset + 1)
            }
        },
        calculatePressed () {
            var dataset = this.datasets[this.focusedDataset]
            var data = dataset.items

            if (this.isTesting) {
                data = sData.formatTestData(this.inputDataTest)
            }
            var summaryData = sData.calculateDataCheck(data)

            if (!_.isEmpty(summaryData.errors)) {
                alert(_.head(summaryData.errors))
            } else {
                this.calculatedData = {
                    datasetName: dataset.name,
                    dataVals: sData.getDataValues(data),
                    tableData: summaryData.output,
                    dotPlot: sData.createDotDataXY(data),
                    boxPlot: sData.getBoxPlotData(summaryData.output)
                }
                this.showModal()
            }
        },
        pdfHover (hover) {
            this.pdfHovering = hover
        },
        generatePdf () {
            this.$refs.pdfGenerator.generate(this.calculatedData)
        },
        removeEvents () {
            window.removeEventListener('keydown', this.handleArrowKeys)
        },
    }
}
</script>

<style scoped>
.pdf-download-btn::before {
    font-size: 28px;
}
</style>
